import Logo from '../assets/logo.png'
import banner from '../assets/banner/homebanner.png'
import Embrace from '../assets/Group14.png'
import Rectangle15 from '../assets/Rectangle15.png'
import Rectangle37 from '../assets/Rectangle37.png'
import Mappa_parco1 from '../assets/MasterPlan/Mappa_parco1.png'
import Isolation_Mode from '../assets/MasterPlan/Isolation_Mode.png'
import Rectangle31 from '../assets/villa/Rectangle31.png'
import Group24 from '../assets/villa/Group24.png'
import gallery01 from '../assets/gallery/gallery01.png'
import gallery02 from '../assets/gallery/gallery02.png'
import AmenitiesBanner from '../assets/Amenities/Rectangle35.png'
import locationBanner from '../assets/location/image.png'
import footerlogo from '../assets/footer/logo.png'
import social_icon from '../assets/social/Group84.png'
import social_icon1 from '../assets/social/Isolation_Mode.png'
import social_icon2 from '../assets/social/Isolation_Mode_.png'
import amenities_icon from '../assets/amenities_icons/Group71.png'
import amenities_icon01 from '../assets/amenities_icons/Group72.png'
import amenities_icon02 from '../assets/amenities_icons/Group73.png'
import amenities_icon03 from '../assets/amenities_icons/Group74.png'
import amenities_icon04 from '../assets/amenities_icons/Group75.png'
import amenities_icon05 from '../assets/amenities_icons/Group68.png'
import amenities_icon06 from '../assets/amenities_icons/Group69.png'
import amenities_icon07 from '../assets/amenities_icons/Group70.png'
import Layer_01 from '../assets/amenities_icons/Layer_01.png'
import Layer_02 from '../assets/amenities_icons/Layer_02.png'
import Layer_1 from '../assets/amenities_icons/Layer_1.png'
import icon_swimming from '../assets/amenities_icons/icon_swimming_.png'
import Group_29 from '../assets/villa/Group_29.png'
import pngtree from '../assets/pngtree.png'
import GroundFloorE3bhk from '../assets/floor/01GroundFloorE3bhk.png'
import FirstFloorE3bhk from '../assets/floor/02FirstFloorE3bhk.png'
import SecondFloorE3bhk from '../assets/floor/SecondFloorE3bhk.png'
import Isolation_Modenew from '../assets/Isolation_Mode.png'
import Mappa_parco_o from '../assets/MasterPlan/Mappa_parco_o.png'


const assets = {
    Logo:Logo,
    banner:banner,
    Embrace:Embrace,
    Rectangle15:Rectangle15,
    Rectangle37:Rectangle37,
    Mappa_parco1:Mappa_parco1,
    Isolation_Mode:Isolation_Mode,
    Group24:Group24,
    Rectangle31:Rectangle31,
    gallery02:gallery02,
    gallery01:gallery01,
    AmenitiesBanner:AmenitiesBanner,
    locationBanner:locationBanner,
    footerlogo:footerlogo,
    social_icon:social_icon,
    social_icon1:social_icon1,
    social_icon2:social_icon2,
    amenities_icon:amenities_icon,
    amenities_icon01:amenities_icon01,
    amenities_icon02:amenities_icon02,
    amenities_icon03:amenities_icon03,
    amenities_icon04:amenities_icon04,
    amenities_icon05:amenities_icon05,
    amenities_icon06:amenities_icon06,
    amenities_icon07:amenities_icon07,
    Layer_01:Layer_01,
    Layer_02:Layer_02,
    Layer_1:Layer_1,
    icon_swimming:icon_swimming,
    Group_29:Group_29,
    pngtree:pngtree,
    GroundFloorE3bhk:GroundFloorE3bhk,
    FirstFloorE3bhk:FirstFloorE3bhk,
    SecondFloorE3bhk:SecondFloorE3bhk,
    Isolation_Modenew:Isolation_Modenew,
    Mappa_parco_o:Mappa_parco_o,
}

export default assets;