import React,{useEffect} from 'react'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
function Overview() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
      <section className='embrace bg-clr overview'>
          <div className='container '>
              <div className='row'>
                  <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                          <div className='justify-content-center' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500">
                              <h4 className='mb-lg-4 mb-sm-4 mb-3 font-italic'>Overview</h4>
                              <h5 className='mb-lg-4 mb-sm-4 mb-3'>Experience Everyday Bliss</h5>
                              <p>Sun-drenched sanctuaries await. Step into our thoughtfully designed villas, where spacious interiors bathed in natural light evoke the tranquility of a secluded hacienda. Every moment here feels like a cherished escape.</p>
                          </div>
                      </div>
                  </div>
                  <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                    <img alt='Overview' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500" src={assets.Rectangle15} className='Overview' />
                  </div>
             </div>
          </div>
      </section>
  )
}

export default Overview