import { BrowserRouter as Router, Routes, Route, Switch, Navigate  } from 'react-router-dom';
import './App.css';
import Home from './views/Home';
function App() {
  return (
    <div className="App">
      <Router basename="/">
    <div className="App pageloaded">
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </div>
  </Router>
    </div>
  );
}

export default App;
