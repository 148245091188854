import React,{useEffect} from 'react'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
function Elevate() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
      <section className='embrace elevate'>
          <div className='container'>
              <div className='row'>
                  <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                      <img alt='Overview' src={assets.Rectangle37} className='Elevate' data-aos='fade-up' data-aos-easing="linear"
                          data-aos-duration="1500" />
                  </div>
                  <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                          <div className='justify-content-center' data-aos='fade-up' data-aos-easing="linear"
                              data-aos-duration="1500">
                              <h5 className='mb-lg-4 mb-sm-4 mb-3 text-lg-end text-sm-end text-center'>Elevate Your Lifestyle</h5>
                              <p className='text-sm-end text-center'>Step outside and explore our meticulously planned landscape, where lush greenery and vibrant blooms transport you to a world of tranquility.</p>
                              <p className='text-sm-end text-center'>Enjoy leisurely strolls along reflexology pathways, unwind in charming hammock gardens, or host unforgettable gatherings in our picturesque outdoor spaces.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default Elevate