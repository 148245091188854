import React,{useEffect} from 'react'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
function Amenities() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
      <section className='embrace no'>
          <div className='container'>
              <div className='row'>
                  <div className='col-lg-12 col-xl-12 col-sm-12 col-12'>
                      <h4 class="mb-lg-4 mb-sm-4 mb-xl-4 mb-3 italic">Amenities</h4>
                      <h5 class="mb-lg-4 mb-sm-4 mb-xl-4 mb-3 text-capitalize">Where luxury meets legacy</h5>
                  </div>
                  <div className='col-lg-7 col-xl-7 col-sm-7 col-12'>
                      <img alt='Overview' src={assets.AmenitiesBanner} className='Elevate mb-lg-4 mb-sm-4 mb-xl-4 mb-3' />
                      <div className='amenities-box'>
                          <h5>Outdoor Amenities</h5>
                          <div className='row mt-3'>
                              <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                                  <div className='row'>
                                      <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                          <div className='img_round text-center'>
                                              <img alt='' src={assets.icon_swimming} />
                                          </div>
                                      </div>
                                      <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                          <div className='card_mp_content'>
                                              <p className='mb-1'>Swimming <br></br> Pool</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                                  <div className='row'>
                                      <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                          <div className='img_round text-center'>
                                              <img alt='' src={assets.Layer_1} />
                                          </div>
                                      </div>
                                      <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                          <div className='card_mp_content'>
                                              <p className='mb-1'>Cricket <br></br>Pitch</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                                  <div className='row'>
                                      <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                          <div className='img_round text-center'>
                                              <img alt='' src={assets.Layer_02} />
                                          </div>
                                      </div>
                                      <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                          <div className='card_mp_content'>
                                              <p className='mb-1'>Tennis <br></br>Court</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                                  <div className='row'>
                                      <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                          <div className='img_round text-center'>
                                              <img alt='' src={assets.Layer_01} />
                                          </div>
                                      </div>
                                      <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                          <div className='card_mp_content'>
                                              <p className='mb-1'>Jogging <br></br>Track</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                                  <div className='row'>
                                      <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                          <div className='img_round text-center'>
                                              <img alt='' src={assets.Isolation_Mode} />
                                          </div>
                                      </div>
                                      <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                          <div className='card_mp_content'>
                                              <p className='mb-1'>Skating <br></br>Rach</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='col-lg-5 col-xl-5 col-sm-5 col-12'>
                    <h5>Indoor Amenities</h5>
                      <div className='row mt-3'>
                       <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Banquet <br></br>Hall</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon02} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Badminton <br></br>Court</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon07} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Gym</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon01} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Indoor <br></br>Games</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon06} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Library</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon03} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Cafeteria</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon05} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Grocery <br></br>Store</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon04} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Salon</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round p-0 text-center'>
                                          <img alt='' src={assets.amenities_icon07} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Outdoor <br></br>Gym</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-6 col-sm-6 col-xl-6 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12 d-flex  
                        align-items-center  
                        justify-content-left'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>Amphi- <br></br>theatre</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default Amenities