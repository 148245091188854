import axios from 'axios';
import { any } from 'prop-types';
const apiURL = process.env.REACT_APP_API_URL;
let API = axios;

console.log('apiURL',apiURL);

export const GetAPI = async (url, callback) => {
  await API.get(apiURL + url)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const GetAPI_body = async ({ url, data }, callback) => {
  await API.get(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const PostAPI = async ({ url, data }, callback) => {
  await API.post(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const PatchAPI = async ({ url, data }, callback) => {
  await API.put(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const DeleteAPI = async ({ url, data }, callback) => {
  await API.delete(apiURL + url, { data: data })
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const DeleteAPI_params = async (url, callback) => {
  await API.delete(apiURL + url)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const GetAPI_PDF = async (url, name) => {
  try {
    const response = await axios.get(apiURL + url, {
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });

    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = name;

    // document.body.appendChild(link);
    // link.click();

    // document.body.removeChild(link);

    // Open the PDF in a new tab
    const newTab = window.open('', '_blank');
    newTab.document.write(
      '<html><head><title>PDF Preview</title></head><body>'
    );

    // Embed the PDF content
    newTab.document.write(
      `<embed width='100%' height='100%' src='${URL.createObjectURL(
        blob
      )}' type='application/pdf'>`
    );

    newTab.document.write('</body></html>');
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};
