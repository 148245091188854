import React,{useEffect} from 'react'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
function Embrace() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <section id="embracee" className='embrace p-100'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h4 className='mb-lg-4 mb-sm-4 mb-3 font-italic' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500">Embrace Spanish Elegance</h4>
          </div>
          <div className='col-lg-8 col-sm-8 col-12' data-aos='fade-up' data-aos-easing="linear"
            data-aos-duration="1500">
            <p className='mb-3'>
            Unwind in timeless grandeur. Surya Valencia's exquisite architecture and design create a captivating ambience, transporting you to a world of luxury.
            </p>
            <p className='mt-3'>
            Located in North Bengaluru, our community beckons you to embrace the art of gracious living, every single day
            </p>
          </div>
          <div className='col-lg-4 col-sm-4 col-12' data-aos='fade-up' data-aos-easing="linear"
            data-aos-duration="1500">
              <div className='row justify-content-end align-items-end'>
                <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                  <img alt='embrace' className='img-fluid' src={assets.Isolation_Modenew} />
                </div>
              </div>
            </div>
        </div>
      </div>
      {/* <img alt='embrace' className='Embrace_img' src={assets.Embrace} /> */}
    </section>
  )
}

export default Embrace