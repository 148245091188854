import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
function Footer() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <footer id="footer">
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3 col-sm-3 col-12'>
                    <div className='footer_logo'>
                        <img alt='' src={assets.footerlogo} className='footerlogo' />
                        {/* <p>Our goal is at the <br></br>heart of all that we do.</p> */}
                        <div class="icons">
                        <a href="https://www.facebook.com/people/Surya-Developers/61559324974103/"><i class="fab fa-facebook"></i></a>
                        {/* <a href="#"><i class="fab fa-linkedin"></i></a> */}
                        <a href="https://www.instagram.com/suryadevelopersblr/?hl=en"><i class="fab fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/surya-developers/"><i class="fab fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>
                <div className='col-lg-9 col-sm-9 col-12'>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-4 col-12'>
                            <h6>Page</h6>
                            <ul>
                                <li><Link>Home</Link></li>
                                <li><Link>Valencia</Link></li>
                                <li><Link>Master Plan</Link></li>
                                <li><Link>Gallery</Link></li>
                            </ul>
                        </div>
                        <div className='col-lg-4 col-sm-4 col-12'>
                            <h6>Support</h6>
                            <ul>
                                <li><Link>Support Center</Link></li>
                                <li><Link>Privacy Policy</Link></li>
                            </ul>
                        </div>
                        <div className='col-lg-4 col-sm-4 col-12'>
                            <h6>Contact us</h6>
                            <ul>
                                <li><Link>+234- 70-1449-8006</Link></li>
                                <li><Link>info@suryadevelopers.in</Link></li>
                                <li><Link>23, Ali express way lagos, Nigeria</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row border-top pt-3'>
                <div className='col-lg-12 col-sm-12 col-12'>
                    <p className='text-center mb-0'>Surya Valencia &copy; 2024, All Rights Reserved</p>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer