import React,{useEffect} from 'react'
import Landing from '../components/home/Landing'
import Navbar from '../components/header/Navbar'
import Footer from '../components/footer/Footer'
import Embrace from '../components/home/Embrace'
import Overview from '../components/home/Overview'
import Elevate from '../components/home/Elevate'
import MasterPlan from '../components/home/MasterPlan'
import Villa from '../components/home/Villa'
import Gallery from '../components/home/Gallery'
import Amenities from '../components/home/Amenities'
import Location from '../components/home/Location'
import Social from '../components/home/Social'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>
    <Navbar />
    <Landing />
    <Embrace />
    <Overview />
    <Elevate />
    <MasterPlan />
    <Villa />
    <Gallery />
    <Amenities />
    <Location />
    <Footer />
    <Social />
    </>
  )
}

export default Home