import React from 'react'
import assets from '../Assets'

function Landing() {
  return (
        <section id="hero" class="d-flex align-items-center">
        <div className='banner'>
        <img alt='homebanner' className='homebanner' src={assets.banner} />
        </div>
        </section>
  )
}

export default Landing