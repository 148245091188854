import React,{useEffect, useState } from 'react'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import assets from '../Assets';
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GetAPI } from '../utility/Api';

function Gallery() {

const [gallery, setGallery] = useState([]);
const [currentIndex, setCurrentIndex] = useState(0);
const getGalleryList = () =>{
  const url = `/users/`
  GetAPI(url, (res)=>{
  const result = res?.data;
  console.log('result',result);
  setGallery(result);
  })
}

useEffect(()=>{
  getGalleryList();
},[])

    useEffect(() => {
        AOS.init();
      }, [])
      const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
          <button className="slick-arrow slick-prev" onClick={onClick}>
            Prev
          </button>
        );
      };
    
      const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
          <button className="slick-arrow slick-next" onClick={onClick}>
            Next
          </button>
        );
      };

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        afterChange: (current) => setCurrentIndex(current)
      };

      

    return (
        <section id="portfolio" className='embrace bg-clr gallery'>
            <div className='container'>
                <div className='row' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500">
                <div className='col-lg-12 col-sm-12 col-12'>
                <h4 class="mb-lg-4 mb-sm-4 mb-xl-4 mb-3">Gallery</h4>
                </div>
                    <div className='col-lg-12 col-sm-12 col-12'>
                    <Slider {...settings}>
                          {gallery?.map(item =>(
                          <div>
                          <div className='gallery-grid'>
                          <p>{item.name}</p>
                          <img alt='Gallery' src={item.image} className='gallery' />
                          </div>
                          </div>
                          ))}
                    </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Gallery