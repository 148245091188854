import React,{useState, useEffect} from 'react'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Villa() {
  const [activeButton, setActiveButton] = useState('3BHK');
  const [currentIndex, setCurrentIndex] = useState(0);
  const bhk3Villa = () =>{
    setActiveButton('3BHK');
  }
  const bhk4Villa = () =>{
    setActiveButton('4BHK');
  }
  useEffect(() => {
    AOS.init();
  }, [])

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="slick-arrow slick-prev" onClick={onClick}>
        Prev
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="slick-arrow slick-next" onClick={onClick}>
        Next
      </button>
    );
  };

  var settings = {
    dots: false,
    infinite: true,
    fade:true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    //pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    afterChange: (current) => setCurrentIndex(current)
  };

  const threeBHK = [
    {
      image:assets.GroundFloorE3bhk,
      title:"3BHK Villa",
      description : "East Facing | North Facing (2 types) SBA: ........ Square Feet",
      floor:"Ground Floor",
    },
    {
      image:assets.FirstFloorE3bhk,
      title:"3BHK Villa",
      description : "East Facing | North Facing (2 types) SBA: ........ Square Feet",
      floor:"First Floor",
    },
    {
      image:assets.SecondFloorE3bhk,
      title:"3BHK Villa",
      description : "East Facing | North Facing (2 types) SBA: ........ Square Feet",
      floor:"Second Floor",
    }
  ]

  const fourBHK = [
    {
      image:assets.Group_29,
      title:"Floor Plan 3BHK",
      description : "East Facing | North Facing (2 types) SBA: ........ Square Feet",
      floor:"Ground Floor",
    },
    {
      image:assets.Group_29,
      title:"4BHK Villa",
      description : "East Facing | North Facing (2 types) SBA: ........ Square Feet",
      floor:"First Floor",
    },
    {
      image:assets.Group_29,
      title:"4BHK Villa",
      description : "East Facing | North Facing (2 types) SBA: ........ Square Feet",
      floor:"Second Floor",
    }
  ]

  return (
    <section className='embrace Villasection'>
        <div className='container'>
         {activeButton === '3BHK' ? (
            <div className='row'>
            <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
            <Slider {...settings}>
           {threeBHK?.map((item, i) => (
             <div key={i}>
              <img data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500" alt='Master Plan' src={item.image} className='Elevate' />
              <p className='text-uppercase text-end'>{item.floor}</p>
              </div>
      ))}
    </Slider>
            </div>
            <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
              <div className='vila_content h-100'>
                <div className='d-flex justify-content-center align-items-center h-100'>
                  <div className='justify-content-center' data-aos='fade-up' data-aos-easing="linear" data-aos-duration="1500">
                    <h3>Floor Plan 3BHK</h3>
                    <p>East Facing | North Facing (2 types) SBA: ........ Square Feet</p>
                    <button className={`btn btn-primary ${activeButton === '3BHK' ? 'active' : ''}`} onClick={bhk3Villa}>3BHK VILLA</button>
                    <button className={`btn btn-primary ${activeButton === '4BHK' ? '' : ''}`} onClick={bhk4Villa}>4BHK VILLA</button>
                    <button className={`btn btn-primary w-auto ${activeButton === '' ? '' : ''}`} >Unlock Price</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
            ) : (
              
            <div className='row'>
              <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
              <Slider {...settings}>
           {fourBHK?.map((item, i) => (
             <div key={i}>
                <img alt='Master Plan' src={item.image} className='Elevate' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500" />
              <p className='text-uppercase text-end'>{item.floor}</p>
              </div>
      ))}
    </Slider>
              </div>
              <div className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                <div className='vila_content h-100'>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <div className='justify-content-center' data-aos='fade-up' data-aos-easing="linear" data-aos-duration="1500">
                      <h3>Floor Plan 4BHK</h3>
                      <p>East Facing | North Facing (2 types) SBA: ........ Square Feet</p>
                      <button className={`btn btn-primary ${activeButton === '3BHK' ? '' : ''}`} onClick={bhk3Villa}>3BHK VILLA</button>
                      <button className={`btn btn-primary ${activeButton === '4BHK' ? 'active' : ''}`} onClick={bhk4Villa}>4BHK VILLA</button>
                      <button className={`btn btn-primary w-auto ${activeButton === '' ? '' : ''}`} >Unlock Price</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
        </div>
        <img alt='Villa' className='Embrace_img villa_img' src={assets.Group24} />
    </section>
  )
}

export default Villa