import React,{useEffect, useState} from 'react'
import assets from '../Assets'
import { Link } from 'react-router-dom'
import ModalPopUp from '../home/Modal';
import { PostAPI } from '../utility/Api';
import pdfFile from '../../assets/surya_valencia_brochure_low_res.pdf'
function Navbar() {
  const handleMenuClick = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMobileNavVisible(false);
  };

  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);

  const handleToggleClick = () => {
    setIsMobileNavVisible(!isMobileNavVisible);
  };

  // const handleMenuClick = (e, section) => {
  //   e.preventDefault();
  //   // Add your scroll logic here, for example using scrollIntoView
  //   const element = document.getElementById(section);
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  //   // Close the mobile menu after clicking a link
  //   setIsMobileNavVisible(false);
  // };
console.log('isMobileNavVisible>>',isMobileNavVisible);
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [data, setData] = useState({

});
const handleSubmit = async (event, action) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    //const validate = await formValidation(data);
    const formatDate = new FormData();
  
    formatDate.append('name', data?.name);
    formatDate.append('description', data?.description);
    formatDate.append('email', data?.email);
    
     if (form.checkValidity()) {
      if(action == 'insert'){
        console.log('data--->',data);
        var url = `/contact/`;
        var params = { url, data:formatDate }
        PostAPI(params, (res) => {
           
            // setShow(false)
            if (res.status == 1) {
              setShow(false);
              // Download the static PDF file
              const pdfUrl = pdfFile; 
              console.log('pdfUrl',pdfUrl);
              const link = document.createElement('a');
              link.href = pdfUrl;
              link.download = 'surya_valencia_brochure_low_res.pdf'; // Replace with your desired file name
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          } else {
              // Handle other status cases
              console.log('Submission failed or other status');
          }
        })
      }
    }
  //resetForm();
  };

  return (
    <>
  <header id="header" className="fixed-top ">
    <div className="container d-flex align-items-center">
      {/* <h1 className="logo me-auto"><a href="index.html"></a></h1> */}
     <Link to={'/'} className="logo me-auto"><img src={assets.Logo} alt="" className="img-fluid" /></Link>
      <nav id="navbar" className={`navbar ${isMobileNavVisible ? 'navbar-mobile' : ''}`}>
        <ul>
          <li><Link className="nav-link scrollto active" to="#hero" onClick={(e) => handleMenuClick(e, 'hero')}>Home</Link></li>
          <li><Link className="nav-link scrollto" to="#embracee" onClick={(e) => handleMenuClick(e, 'embracee')}>Valencia</Link></li>
          {/* <li><a className="nav-link scrollto" href="#section1" onClick={(e) => handleMenuClick(e, 'section1')}>Master Plan</a></li> */}
          <li><Link className="nav-link scrollto" to="#masterplan" onClick={(e) => handleMenuClick(e, 'masterplan')}>Master Plan</Link></li>
          <li><Link className="nav-link scrollto" to="#portfolio" onClick={(e) => handleMenuClick(e, 'portfolio')}>Gallery</Link></li>
          <li><Link className="nav-link scrollto" to="#location" onClick={(e) => handleMenuClick(e, 'location')}>Location</Link></li>
          <li><Link className="getstarted scrollto" to="#" onClick={handleShow} >Download Brochure</Link></li>
        </ul>
        <i className={`bi mobile-nav-toggle ${isMobileNavVisible ? 'bi-x' : 'bi-list'}`} onClick={handleToggleClick}></i>
      </nav>
    </div>
  </header>
  <ModalPopUp 
        handleSubmit={handleSubmit}
        setData={setData} 
        data={data} 
        show={show} 
        setShow={setShow} 
        handleClose={handleClose} 
        handleShow={handleShow} />
    </>
  )
}

export default Navbar