import React,{useEffect} from 'react'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
function Location() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
      <section id='location' className='embrace bg-clr'>
          <div className='container'>
              <div className='row' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500">
                  <div className='col-lg-12 col-sm-12 col-12'>
                      <h4 className="mb-lg-4 mb-sm-4 mb-xl-4 mb-3 italic">Location</h4>
                      <h5 className="mb-lg-4 mb-sm-4 mb-xl-4 mb-3">Discover Prime Location</h5>
                      <div className='row'>
                        <div className='col-lg-10 col-sm-10 col-12 mb-lg-4 mb-sm-4 mb-3'>
                         <p>Located in North Bengaluru, Surya Valencia offers the perfect blend of tranquility & convenience.  Enjoy easy access to bustling city centers, top-notch schools, & recreational facilities, ensuring that every aspect of your lifestyle is catered to.</p>
                        </div>
                      </div>
                  </div>
                  <div className='col-lg-5 col-sm-5 col-12'>
                      <img alt='location' src={assets.locationBanner} className='w-100' />
                  </div>
                  <div className='col-lg-7 col-sm-7 col-12'>
                      <div className='location-map'>
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36966.710032852214!2d77.55376404438934!3d13.121551023531982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae185e57a7144f%3A0xd8365d4f23d3c88a!2sSurya%20Developers!5e0!3m2!1sen!2sin!4v1713683520902!5m2!1sen!2sin" width="600" height="450" style={{border:'0px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default Location