import React,{useState} from 'react'
import { Modal, Button, Form } from "react-bootstrap";

function ModalPopUp({show, setShow, handleClose,handleShow,validated, handleSubmit,setData, data, id }) {
    const [visible, setVisible] = useState(false)
  return (
    <>
          <Modal size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={show} onHide={handleClose}>
              <h5 className='p-2 text-center w-100'>Avail the limited period offer.</h5>
              <Modal.Header className='text-center' closeButton>
                  <Modal.Title>ENQUIRE NOW</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                        <Form
                        noValidate
                        validated={validated}
                        onSubmit={(e) => handleSubmit(e, id ? 'update' : 'insert')}
                        value={id ? "update" : "insert"}
                        >
                      <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" placeholder="Enter name"
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                          value={data?.name}
                          />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="email" placeholder="Enter email"
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                          value={data?.email}
                          />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicDescription">
                          <Form.Label>Description</Form.Label>
                          <Form.Control type="text" placeholder="Enter description" 
                          onChange={(e) =>
                            setData({ ...data, description: e.target.value })
                          }
                          value={data?.description}
                          />
                      </Form.Group>
                      <div className='text-center'>
                      <Button variant="primary" type="submit">
                          Submit
                      </Button>
                      </div>
                  </Form>
              </Modal.Body>
          </Modal>
    </>
  )
}

export default ModalPopUp