import React,{useState} from 'react'
import assets from '../Assets'
import ModalPopUp from './Modal';
import { PostAPI } from '../utility/Api';
function Social(props) {
const {} = props;
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [data, setData] = useState({

});
const handleSubmit = async (event, action) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    //const validate = await formValidation(data);
    const formatDate = new FormData();
  
    formatDate.append('name', data?.name);
    formatDate.append('description', data?.description);
    formatDate.append('email', data?.email);
    
     if (form.checkValidity()) {
      if(action == 'insert'){
        console.log('data--->',data);
        var url = `/contact/`;
        var params = { url, data:formatDate }
        PostAPI(params, (res) => {
            //setVisible(false);
           // setFlag(!flag);
            //toast.success('Submit Successfully!');
            setShow(false)
        })
      }
    }
  //resetForm();
  };
  
  return (
      <div>
        <ModalPopUp 
        handleSubmit={handleSubmit}
        setData={setData} 
        data={data} 
        show={show} 
        setShow={setShow} 
        handleClose={handleClose} 
        handleShow={handleShow} />
          <div class="float-sm">
              <div class="fl-fl float-fb">
                 <a href="https://api.whatsapp.com/send?phone=%2B919019748484" target="_blank"> <img alt='' src={assets.social_icon} className='s_icon' /></a>
              </div>
              <div class="fl-fl float-tw" onClick={handleShow}>
              <img alt='' src={assets.social_icon1} className='s_icon mt-2' /> <a  href="" target="_blank"> </a>
              </div>
              <div class="fl-fl float-gp">
                  <a href="mailto:info@suryadevelopers.in" target="_blank"><img alt='' src={assets.social_icon2} className='s_icon' /> </a>
              </div>
          </div>
      </div>
  )
}

export default Social