import React,{useEffect} from 'react'
import assets from '../Assets'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
function MasterPlan() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <section id="masterplan" className='embrace bg-clr elevate'>
          <div className='container'>
              <div className='row'>
                  <div className='col-lg-7 col-xl-7 col-sm-7 col-12'>
                      <h4 className='mb-lg-4 mb-sm-4 mb-xl-4 mb-3 font-italic italic noleft' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500">Master Plan</h4>
                      <h5 className='mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1500">Where Luxury Meets Legacy: Live the dream.</h5>
                      <div className='row'>
                          <div className='col-lg-8 col-xl-8 col-sm-8 col-12'>
                              <p data-aos='fade-up' data-aos-easing="linear"
                                  data-aos-duration="1500">80 exquisite villas, inspired by Spanish grandeur, redefine living with world-class amenities</p>
                          </div>
                      </div>   
                      <div className='row mt-lg-5 mt-sm-5 mt-xl-5 mt-3'>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3'>
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-lg-4 col-sm-4 col-xl-4 col-6 mb-lg-4 mb-sm-4 mb-xl-4 mb-3' data-aos='fade-up' data-aos-easing="linear"
              data-aos-duration="1000">
                              <div className='row'>
                                  <div className='col-lg-4 col-sm-4 col-xl-4 col-12'>
                                      <div className='img_round'>
                                          <img alt='' src={assets.Isolation_Mode} />
                                      </div>
                                  </div>
                                  <div className='col-lg-8 col-sm-8 col-xl-8 col-12'>
                                      <div className='card_mp_content'>
                                          <p className='mb-1'>01</p>
                                          <p className='mb-1'>Reflexology</p>
                                          <p className='mb-1'>Pathway</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                  <div className='col-lg-5 col-xl-5 col-sm-5 col-12 position-relative'>
                      <img alt='Master Plan' src={assets.Mappa_parco_o} className='Elevate MasterPlan' data-aos='fade-up' data-aos-easing="linear"
                          data-aos-duration="1500" />
                      <img alt='pngtree' src={assets.pngtree} className='pngtree' />
                  </div>
             </div>
          </div>
      </section>
  )
}

export default MasterPlan